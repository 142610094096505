<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600">
            Masters
          </h2>
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-lg-7 row">
                <div class="col-lg-4 col-sm-3 mt-1">
                  <datepicker
                    v-model="fromDate"
                    :upper-limit="toDate"
                    class="form-control"
                    placeholder="From Date" />
                </div>
                <div class="col-lg-4 col-sm-3 mt-1">
                  <datepicker
                    v-model="toDate"
                    :lower-limit="fromDate"
                    class="form-control"
                    placeholder="To Date" />
                </div>
                <div class="col-lg-4 col-sm-3 mt-1">
                  <Field
                    type="text"
                    class="form-control"
                    name="ledger_balance"
                    placeholder="SEARCH"
                    v-model="keyword"
                    v-on:keyup="getList(1)" />
                </div>
              </div>
              <div class="col-lg-5 ml-4 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div
            class="col-lg-2"
            style="
              font-weight: bold;
              margin-top: 10px;
              text-align: initial;
              font-size: 13px;
            ">
            Total Master : {{ count }}
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Sr No.</th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('name', 'id-name')">
                  Name
                </th>
                <th
                  class="head sorting"
                  id="id-code"
                  v-on:click="sorting('code', 'id-code')">
                  Login ID
                </th>
                <th class="head">Server Code</th>
                <th class="head">Manager</th>
                <th class="head">Join Time</th>
                <th class="head">Active/De-Active</th>
                <th class="head">Limit Order</th>
                <th class="head">Fresh Stoploss</th>
                <th class="head">Auto Squareoff</th>
                <th class="head">Only SquareOff</th>
                <th class="head">Action</th>
                <th class="head">Access</th>
                <th class="head">Script Margin</th>
                <th class="head">Jobbing Time</th>
                <th class="head">Brokerage-Setup</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">
                  {{ ($refs.listPagination.currentPage - 1) * 20 + index + 1 }}
                </td>
                <td class="body">
                  {{ data.name ? $helperService.getUpperCase(data.name) : "" }}
                </td>
                <td class="body">{{ data.code }}</td>
                <td class="body">{{ data.server_code }}</td>
                <td class="body">
                  {{
                    data.manager && data.manager.name
                      ? $helperService.getUpperCase(data.manager.name)
                      : "-"
                  }}
                </td>
                <td class="body">
                  {{
                    data.join_date
                      ? $helperService.getCustomDateYear(data.join_date)
                      : ""
                  }}
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showDeletePopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="ACTIVE"
                      style="width: 37px; height: 19px"
                      false-value="INACTIVE"
                      v-model="data.status"
                      :id="'switch_' + index" />
                    <label class="form-check-label" for="switch_1"> </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showLimitPopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="1"
                      style="width: 37px; height: 19px"
                      false-value="0"
                      v-model="data.is_limit"
                      :id="'switch1_' + index" />
                    <label class="form-check-label" :for="'switch1_' + index">
                    </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showFreshLimitPopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="1"
                      style="width: 37px; height: 19px"
                      false-value="0"
                      v-model="data.is_fresh_order"
                      :id="'switc_' + index" />
                    <label class="form-check-label" :for="'switc_' + index">
                    </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showAutoSquarePopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="1"
                      style="width: 37px; height: 19px"
                      false-value="0"
                      v-model="data.is_auto_square_off"
                      :id="'swit_' + index" />
                    <label class="form-check-label" :for="'swit_' + index">
                    </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showSquareOffPopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="1"
                      style="width: 37px; height: 19px"
                      false-value="0"
                      v-model="data.is_only_position_square_off"
                      :id="'switch_' + index" />
                    <label class="form-check-label" for="switch_1"> </label>
                  </div>
                </td>
                <td class="body" style="padding: 10px">
                  <a
                    v-on:click="$router.push('/edit-account/' + data.id)"
                    class="fa fa-pencil-square-o mr-2"
                    style="
                      cursor: pointer;
                      color: rgb(29, 68, 91);
                      font-size: 21px;
                    "
                    title="Edit"></a>
                  &nbsp;
                </td>
                <td class="body">
                  <i
                    class="fa fa-cog mt-1"
                    aria-hidden="true"
                    style="font-size: 15px; cursor: pointer"
                    v-on:click="$router.push('/access/' + data.id)"></i>
                </td>
                
                <td class="body">
                  <img
                  class="mt-1"
                    title="Script Margin"
                    v-if="data.is_script_wise"
                    src="/images/script-margin.png"
                    v-on:click="
                      $router.push('/master-script-margin-limit/' + data.id)
                    "
                    width="25"
                    height="25"
                    style="cursor: pointer"
                    alt="Script Margin" />
                </td>
                <td class="body">
                  {{ data.minute ? data.minute + " Min" : "" }}
                </td>
                <td class="body">
                  <i
                    class="fa fa-eye mt-1"
                    aria-hidden="true"
                    style="font-size: 15px; cursor: pointer"
                    v-on:click="$router.push('/brokerages/' + data.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import swal from "sweetalert2";
import moment from "moment";
import { Field } from "vee-validate";
import Datepicker from "vue3-datepicker";

export default {
  components: {
    ErrorComponent,
    Datepicker,
    Field,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      list: [],
      fromDate: "",
      toDate: "",
      count: 0,
      type: "",
      keyword: "",
      managerList: [],
    };
  },
  mounted() {
    this.checkAccess();
    this.getManager();
  },
  methods: {
     checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_master == 0) {
        this.$router.push("/access-denied");
      }
    },
    getManager() {
      this.loader = true;
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: { role: "MANAGER" },
        })
        .then((res) => {
          this.loader = false;
          this.managerList = res.list;
          this.getList(0);
        })
        .catch(() => {
          this.loader = false;
          this.getList(0);
        });
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(1, obj.order_by, obj.sort_by);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      if (this.type) {
        searchObj.type = this.type;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      searchObj.role = "MASTER";
      searchObj.offset = page - 1;
      searchObj.limit = 20;
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          for (var obj of this.list) {
            for (var manger of this.managerList) {
              if (manger.id == obj.manager_id) {
                obj.manager = manger;
              }
            }
          }

          this.count = res.count;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 20);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showSquareOffPopup(data) {
      var type = "";
      if (data.is_only_position_square_off == 0) {
        type = "Inactive";
      } else {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " only squareoff",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-square-of-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
    showLimitPopup(data) {
      var type = "";
      if (data.is_limit == 0) {
        type = "Inactive";
      } else {
        type = "Active";
      }

      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " limit Orders",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-limit_order-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
    showAutoSquarePopup(data) {
      var type = "";
      if (data.is_auto_square_off == 0) {
        type = "Inactive";
      } else {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + "  auto squareoff",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/auto-square-off/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
    showFreshLimitPopup(data) {
      var type = "";
      if (data.is_fresh_order == 0) {
        type = "Inactive";
      } else {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " fresh limit Orders",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/fresh-limit-order/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
    showDeletePopup(data) {
      var type = "";
      if (data.status == "ACTIVE") {
        type = "Active";
      }else {
        type = "Inactive";
      }

      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " account",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-account-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
  },
};
</script>
