<template>
  <div v-show="type != 'ADMIN'" class="text-center loginbody">
    <Form @submit="login" class="form-signin">
      <a class="psw-icon" style="margin-top: -100px"
        ><img :src="logo" height="70"
      /></a>
      <div class="text-left mb-4">
        <label style="color: black">Server Code</label>
        <span class="text-danger">*</span>
        <Field
          type="text"
          class="form-control"
          name="Code"
          id="code"
          placeholder="Code"
          v-model="details.server_code"
          style="height: 35px !important"
          rules="required:code"
          :validateOnInput="true" />
        <ErrorMessage name="Code" class="text-danger" />
      </div>
      <div class="text-left mb-4">
        <label style="color: black">Username</label>
        <span class="text-danger">*</span>
        <Field
          type="text"
          class="form-control"
          name="Username"
          id="username"
          placeholder="Username"
          v-model="details.code"
          style="height: 35px !important"
          rules="required:username"
          :validateOnInput="true" />
        <ErrorMessage name="Username" class="text-danger" />
      </div>
      <div class="text-left">
        <label style="color: black">Password</label>
        <span class="text-danger">*</span>
        <div class="input-group mb-3">
          <Field
            :type="newPasswordFieldType"
            id="password"
            name="password"
            class="form-control password"
            placeholder="Password"
            v-model="details.password"
            v-on:keydown.space="$event.preventDefault()"
            rules="required"
            :validateOnInput="true" /><span
            v-on:click="switchVisibilityNew"
            class="input-group-text"
            id="basic-addon2"
            style="
              border-radius: 0px 8px 8px 0px;
              height: 30px;
              margin-left: -3px;
              cursor: pointer;
            "
            ><a class="psw-icon" style="margin-top: -6px"
              ><img :src="imageNew" width="20" /></a
          ></span>
        </div>
        <ErrorMessage name="_password" class="text-danger" />
      </div>
      <!-- <div class="checkbox text-right mb-3">
        <label>
          <router-link to="/forgot">Forgot Password?</router-link>
        </label>
      </div> -->
      <div class="col-12 row ml-2 mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="rememberMe"
          v-model="isChecked"
          :true-value="true"
          :false-value="false" /><label
          class="form-check-label"
          for="rememberMe"
          style="color: black">
          Remember Me
        </label>
      </div>
      <div class="col-12 row mt-4" style="font-size: 12px; color: black">
        <div class="custom-control custom-checkbox" style="margin-left: 2px">
          <input
            class="custom-control-input"
            id="checkTC"
            type="checkbox"
            v-on:change="is_checked()"
            v-model="terms_condition"
            name="tc"
            value="true" /><label class="custom-control-label" for="checkTC"
            ><span class="text-muted"
              >No real money involved. This is a virtual Trading Application for
              exchanging views on markets. This is for training purpose only
              <a href="#"><a v-on:click="open()">Terms &amp; Conditions</a></a
              ><span class="text-danger">*</span></span
            ></label
          >
          <span
            v-if="is_show"
            class="text-error"
            style="font-size: 14px; color: red; font-weight: 501"
            >You must accept the Terms and Conditions</span
          >
        </div>
      </div>
      <button
        class="btn btn-lg btn-primary btn-block mt-3"
        type="submit"
        id="login-button">
        LOGIN
      </button>
      <button
        class="btn btn-lg btn-secondary btn-block mt-3"
        type="button"
        v-on:click="donwloadAPK"
        id="download-button">
        <svg
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        DOWNLOAD APK
      </button>
    </Form>
    <OtpModal ref="otpItem" />
    <TextModal ref="firstLogin">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.firstLogin.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword()">
        <!-- <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="_password"
              class="form-control"
              placeholder="Old Password"
              v-model="old_password"
              rules="required:old password"
              :validateOnInput="true"
            />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div> -->
        <div
          class="col-lg-12 col-xs-12 form-group align-items-center mb-3"
          style="text-align: left">
          <label class="col-form-label" style="display: flex">
            New Password
            <span class="text-danger">*</span>
          </label>
          <div class="input-group mb-3">
            <Field
              :type="newPasswordFieldType"
              name="name_password"
              class="form-control form-group"
              placeholder="New Password"
              v-model="new_password"
              rules="required:new password|minLength:6"
              :validateOnInput="true" /><span
              v-on:click="switchVisibilityNew"
              class="input-group-text"
              id="basic-addon2"
              style="
                border-radius: 0px 8px 8px 0px;
                height: 30px;
                margin-left: -3px;
                cursor: pointer;
              "
              ><a class="psw-icon" style="margin-top: -6px"
                ><img :src="imageNew" width="20" /></a
            ></span>
          </div>
          <ErrorMessage name="name_password" class="text-danger" />
        </div>
        <div
          class="col-lg-12 col-xs-12 form-group align-items-center mb-3"
          style="text-align: left">
          <label class="col-form-label" style="display: flex">
            Confirm New Password
            <span class="text-danger">*</span>
          </label>
          <div class="input-group mb-3">
            <Field
              :type="newPasswordFieldTypeV2"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true" />
            <span
              v-on:click="switchVisibilityNewV2"
              class="input-group-text"
              id="basic-addon2"
              style="
                border-radius: 0px 8px 8px 0px;
                height: 30px;
                margin-left: -3px;
                cursor: pointer;
              "
              ><a class="psw-icon" style="margin-top: -6px"
                ><img :src="imageNewV2" width="20" /></a
            ></span>
          </div>
          <ErrorMessage name="name_ccpassword" class="text-danger" />
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="change-password-button">Save</button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import OtpModal from "@/components/OtpModel.vue";
import TextModal from "@/components/TextModal";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    OtpModal,
    TextModal,
  },
  name: "Login",
  computed: {},
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("client_ids");
    if (localStorage.getItem("isChecked")) {
      this.isChecked =
        localStorage.getItem("isChecked") == "true" ? true : false;
      this.details.server_code = localStorage.getItem("server_code");
      this.details.password = localStorage.getItem("password");
      this.details.code = localStorage.getItem("code");
    }
    if (this.type == "ADMIN") {
      this.loginAdmin();
    }
  },
  data() {
    return {
      id: "",
      type: this.$route.params.type,
      newPasswordFieldType: "password",
      PasswordFieldType: "password",
      newPasswordFieldTypeV2: "password",
      image: "/images/show-psw.svg",
      imageNew: "/images/show-psw.svg",
      imageNewV2: "/images/show-psw.svg",
      logo: "/img/logo_new.png",
      terms_condition: true,
      isChecked: false,
      is_show: false,
      details: {
        server_code: "50986",
        code: "",
        password: "",
      },
    };
  },
  methods: {
    switchVisibilityNewV2() {
      this.newPasswordFieldTypeV2 =
        this.newPasswordFieldTypeV2 === "password" ? "text" : "password";
      this.imageNewV2 =
        this.imageNewV2 === "/images/show-psw.svg"
          ? "/images/hide-psw.svg"
          : "/images/show-psw.svg";
    },
    changePassword() {
      var obj = {};
      obj.new_password = this.new_password;
      obj.id = this.id;
      this.$api
        .putAPI({
          _action: "/first-login",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.firstLogin.closeModal();
          this.$notify({
            title: "Success",
            type: "success",
            text: "You logged in successfully.",
          });
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show-psw.svg"
          ? "/images/hide-psw.svg"
          : "/images/show-psw.svg";
    },
    is_checked() {
      this.is_show = false;
    },
    open() {
      window.open("/terms");
    },
    loginAdmin() {
      localStorage.removeItem("temp_token");
      this.details.server_code = 678910;
      this.details.code = "master_admin";
      this.details.password = "master_admin";
      localStorage.setItem("server_code", this.details.server_code);
      localStorage.setItem("password", this.details.password);
      localStorage.setItem("code", this.details.code);
      this.details.source = "WEB";
      this.$api
        .postAPI({
          _action: "/ouath-token",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "You logged in successfully.",
            });
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    login() {
      localStorage.removeItem("temp_token");
      if (this.terms_condition == false) {
        this.is_show = true;
        return;
      }

      if (this.details.code == "admin") {
        this.details.server_code = 12345;
      }
      if (this.details.code == "master_admin") {
        this.details.server_code = 678910;
      }
      if (this.details.code == "super_admin") {
        this.details.server_code = 67890;
      }
      this.details.source = "WEB";
      if (this.isChecked == true) {
        var saved_logins = JSON.parse(
          localStorage.getItem("saved_logins") || "[]"
        );
        var count = 0;
        for (var i in saved_logins) {
          if (
            saved_logins[i].server_code == this.details.server_code &&
            saved_logins[i].password == this.details.password &&
            saved_logins[i].code == this.details.code
          ) {
            count++;
          }
        }
        if (count == 0) {
          var obj = {};
          obj.server_code = this.details.server_code;
          obj.password = this.details.password;
          obj.code = this.details.code;
          saved_logins.push(obj);
          localStorage.setItem("saved_logins", JSON.stringify(saved_logins));
        }

        localStorage.setItem("server_code", this.details.server_code);
        localStorage.setItem("password", this.details.password);
        localStorage.setItem("code", this.details.code);
        localStorage.setItem("isChecked", this.isChecked);
      } else {
        localStorage.removeItem("server_code");
        localStorage.removeItem("password");
        localStorage.removeItem("code");
        localStorage.removeItem("isChecked");
      }
      this.$api
        .postAPI({
          _action: "/ouath-token",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            if (res && res.is_first_login == true) {
              this.id = res.id;
              this.$refs.firstLogin.showModal();
              this.$refs.changePasswordForm.resetForm();
            } else {
              this.$notify({
                title: "Success",
                type: "success",
                text: "You logged in successfully.",
              });
              localStorage.setItem("token", res.access_token);
              this.getLoginUserDetail();
            }
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            this.getAccess();
            localStorage.setItem("role", res.role);
            localStorage.setItem("userid", res.id);
            localStorage.setItem("code", res.code);
            localStorage.setItem("client_ids", JSON.stringify(res.client_ids));
            if (res.role == "ADMIN") {
              this.$router.push("/super-masters");
            } else if (res.role == "SUPER_ADMIN") {
              this.$router.push("/tenents");
            } else {
              localStorage.setItem("is_edit", res.is_edit);
              localStorage.setItem(
                "is_add_trade_permission",
                res.is_add_trade_permission
              );
              this.$router.push("/dashboard");
            }
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("client_ids");
        });
    },
    getAccess() {
      this.$api
        .getAPI({
          _action: "/user-access",
        })
        .then((res) => {
          if (res) {
            var token = localStorage.getItem("temp_token");
            if (token) {
              var access = {
                is_summary: 1,
                is_user: 1,
                is_orderEdit: 1,
                is_orderDelete: 1,
                is_orderExecute: 1,
                is_orderExport: 1,
                is_tradeAdd: 1,
                is_tradeEdit: 1,
                is_tradeDelete: 1,
                is_tradeExport: 1,
                is_tradeReverse: 1,
                is_rollover: 1,
                is_closePosition: 1,
                is_positionBook: 1,
                is_PositionExport: 1,
                is_summaryReport: 1,
                is_branchReport: 1,
                is_ledgerReport: 1,
                is_settlement: 1,
                is_accountEditLog: 1,
                is_orderEditLog: 1,
                is_quantitySetting: 1,
                is_orderLimit: 1,
                is_segmentSetting: 1,
                is_blockScript: 1,
                is_blockIp: 1,
                is_transferSetting: 1,
                is_manager: 1,
                is_master: 1,
                is_broker: 1,
                is_customer: 1,
                is_addAccount: 1,
                is_checkboxEdit: 1,
                is_ledgerEdit: 1,
              };
              this.$store.commit("setAccess", access);
              localStorage.setItem("access", JSON.stringify(access));
            } else {
              this.$store.commit("setAccess", res.access);
              localStorage.setItem("access", JSON.stringify(res.access));
            }
          }
        })
        .catch(() => {});
    },
    donwloadAPK() {
      window.open("/" + this.$api.apkName, "_black");
    },
  },
};
</script>
