<template>
  <div v-show="false">
    <div id="printablePrintNew">
      <page>
        <div class="col-12 row" style="
            margin-bottom: 8px;
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 1%;
          ">
          <div style="font-size: 12px; width: 24%">
            <!-- {{ currentTime }} -->
          </div>
          <div class="text-center" style="font-size: 20px; width: 50%">
            SUMMARY REPORT PDF
          </div>
          <div class="text-right" style="font-size: 12px; padding-right: 0px; width: 25%">
            <!-- {{ week }} -->
          </div>
        </div>

        <div>
          <div class="bodyclass print">
            <div style="border: black solid 2px">
              <div style="
                  color: #000;
                  margin-top: 3px;
                  margin-bottom: 3px;
                  text-align: center;
                  font-size: 11px;
                  font-weight: 600;
                ">
                <!-- {{ obj?.trading_symbol }} -->
              </div>
              <hr style="margin-top: 0px; margin-bottom: -8px" />
              <div style="padding: 8px">
                <table width="100%" cellpadding="0" cellspacing="0" id="t1">
                  <thead>
                    <tr>
                      <th class="head" style="font-size: 8px; padding-bottom: 5px; text-align: center">
                        SR.NO
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        CLIENT NAME
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        WEEK
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        GROSS MTM
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        BROKERAGE
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        NET MTM
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                       DOWNLINE
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        UPLINE
                      </th>
                      <th class="head" style="font-size: 8px;padding-bottom: 5px; text-align: center">
                        SELF
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in list" :key="index">
                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: center;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ data.id }}
                      </td>
                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: left;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{
                          data.user && data.user.name
                          ? data.user.name + " ( " + data.user.code + " )"
                          : ""
                        }}
                      </td>
                      <td class="body" style="
                          text-align: center;
                          font-size: 8px !important;
                          font-weight: 600;
                          padding-bottom: 5px;
                        ">
                        {{ data.remark ? data.remark : "" }}
                      </td>
                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        " :style="data.gross_mtm < 0 ? 'color: red' : 'color: blue'
                          ">
                        {{
                          data.gross_mtm
                          ? $helperService.getFormattedPrice(data.gross_mtm)
                          : 0
                        }}
                      </td>
                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        " :style="data.brokerage < 0 ? 'color: red' : 'color: blue'
                            ">
                        {{
                          data.brokerage
                          ? $helperService.getFormattedPrice(data.brokerage)
                          : 0
                        }}
                      </td>
                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        " :style="data.balance < 0 ? 'color: red' : 'color: blue'">
                        {{
                          data.balance
                          ? $helperService.getFormattedPrice(data.balance)
                          : 0
                        }}
                      </td>

                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        " >
                        {{
                          data.downline
                          ? $helperService.getFormattedPrice(data.downline)
                          : 0
                        }}
                      </td>

                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{
                          data.upline
                          ? changeValue($helperService.getFormattedPrice(data.upline))
                          : 0
                        }}
                      </td>

                      <td class="body" style="
                          padding-bottom: 5px;
                          text-align: right;
                          font-size: 8px !important;
                          font-weight: 600;
                        " :style="data.self < 0 ? 'color: red' : 'color: blue'">
                        {{
                          data.self
                          ? changeValue($helperService.getFormattedPrice(data.self))
                          : 0
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="total" colspan="2"></td>
                      <td class="total" style="
                          text-align: center;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        Total
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ gross_mtm }}
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ brokerage }}
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ net_mtm }}
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ downline }}
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{ changeValue(upline) }}
                      </td>
                      <td class="total" style="
                          text-align: right;
                          padding-bottom: 5px;
                          font-size: 8px !important;
                          font-weight: 600;
                        ">
                        {{  changeValue(self) }}
                      </td>

                      <td class="total" colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </page>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";

export default {
  name: "PrintSummaryReport",
  data() {
    return {
      list: [],
      name: "",
      week: "",
      gross_mtm: 0,
      brokerage: 0,
      net_mtm: 0,
      downline: 0,
      upline: 0,
      self : 0
    };
  },
  computed: {},
  mounted() { },
  methods: {
    changeValue(value) {
      if (value <= 0) {
        return Math.abs(value);
      } else {
        return 0 - value;
      }
    },

    setDataAndPrint(list, gross_mtm, brokerage, net_mtm, downline, upline, self) {
      this.list = list;
      this.gross_mtm = gross_mtm.toFixed(2);
      this.brokerage = brokerage.toFixed(2);
      this.net_mtm = net_mtm.toFixed(2);
      this.downline = downline.toFixed(2);
      this.upline = upline.toFixed(2);
      this.self = self.toFixed(2);

      html2pdf(document.getElementById("printablePrintNew"), {
        margin: [15, 10, 15, 10],
        image: { type: "jpeg", quality: 1 },
        filename: "Summary-report.pdf",
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: {
                        mode: ['avoid-all', 'css', 'legacy']
                    }
      });
    },
  },
};
</script>
