<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start">
                  Client
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="detail.user_id"
                  :options="client_list"
                  @select="getList()"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                  >Segment
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="detail.exchange"
                  :options="exchanges"
                  @select="getList()"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start">
                  Trade On
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder="Trade On"
                  v-model="detail.trade_on"
                  :options="trades"
                  @select="getList()"
                  searchable="true" />
              </div>

              <div class="col-lg-1 col-sm-1">
                <label class="col-form-label">
                  No. Of Scripts
                  <span class="text-danger">*</span> </label
                ><Field
                  name="positionLimit"
                  v-model="detail.no_of_scripts"
                  type="number"
                  style="width: 90px !important"
                  class="form-control-new"
                  value="0" />
              </div>

              <div class="col-lg-1 col-sm-1">
                <label class="col-form-label" style="display: flex">
                  No. Of Lot
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="maxOrder"
                  type="number"
                  style="width: 90px !important"
                  v-model="detail.margin_amount"
                  class="form-control-new"
                  value="0" />
              </div>

              <div class="col-lg-2 col-sm-2 mt-3">
                <div class="custom-control custom-radio mb-1">
                  <input
                    class="custom-control-input"
                    id="radio_1022"
                    name="settingType"
                    v-model="detail.is_limit_only_if_position_exist"
                    type="checkbox"
                    :true-value="1"
                    :false-value="0" /><label
                    class="custom-control-label"
                    style="font-size: 12px"
                    for="radio_1022"
                    >Limit Only If Position Exist</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    name="settingType"
                    id="radio_10223"
                    type="checkbox"
                    v-model="detail.is_first_sell_not_allowed"
                    :true-value="1"
                    :false-value="0" /><label
                    class="custom-control-label"
                    style="font-size: 12px"
                    for="radio_10223"
                    >Is First Sell Not Allowed</label
                  >
                </div>
              </div>
              <div class="col-lg-2 col-sm-2 mt-4">
                <button
                  class="save-btn"
                  style="width: 85px !important; margin-top: 0 !important"
                  id="save-button"
                  type="button"
                  v-on:click="save()">
                  {{ detail.id ? "EDIT" : "ADD" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div class="col-lg-6">
              <Field
                type="text"
                class="col-lg-3 form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()" />
            </div>
            <div class="col-lg-6 text-right">
              <button
                v-on:click="deleteSelecOne()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button">
                DELETE
              </button>
            </div>
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)" />
                    Is Delete
                  </div>
                </th>
                <th
                  class="head sorting"
                  id="id-Client"
                  v-on:click="sorting('users.name', 'id-Client')">
                  Client
                </th>
                <th
                  class="head sorting"
                  id="id-exchange"
                  v-on:click="sorting('exchange', 'id-exchange')">
                  Segment
                </th>
                <th
                  class="head sorting"
                  id="id-trade_on"
                  v-on:click="sorting('trade_on', 'id-trade_on')">
                  Trade On
                </th>
                <th
                  class="head sorting"
                  id="id-no_of_scripts"
                  v-on:click="sorting('no_of_scripts', 'id-no_of_scripts')">
                  No. of Script
                </th>
                <th
                  class="head sorting"
                  id="id-Client"
                  v-on:click="sorting('margin_amount', 'id-margin_amount')">
                  No of Lot
                </th>
                <th
                  class="head sorting"
                  id="id-is_limit_only_if_position_exist"
                  v-on:click="
                    sorting(
                      'is_limit_only_if_position_exist',
                      'id-is_limit_only_if_position_exist'
                    )
                  ">
                  Limit (If Position)
                </th>
                <th
                  class="head sorting"
                  id="id-is_first_sell_not_allowed"
                  v-on:click="
                    sorting(
                      'is_first_sell_not_allowed',
                      'id-is_first_sell_not_allowed'
                    )
                  ">
                  Is First Sale Not Allowed
                </th>
                <th class="head">Action</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body" style="text-align: start">
                  <div class="switchToggleRead">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false" />
                  </div>
                </td>
                <td class="body">{{ data.user ? data.user.name : "" }}</td>
                <td class="body">
                  {{ data.exchange == "NFO" ? "NSE" : "MCX" }}
                </td>
                <td class="body">{{ data.trade_on }}</td>
                <td class="body">{{ data.no_of_scripts }}</td>
                <td class="body">{{ data.margin_amount }}</td>
                <td class="body">{{ data.is_limit_only_if_position_exist }}</td>
                <td class="body">{{ data.is_first_sell_not_allowed }}</td>
                <td class="body">
                  <a
                    v-on:click="getDetail(data)"
                    class="fa fa-pencil-square-o mr-2"
                    style="
                      cursor: pointer;
                      color: rgb(29, 68, 91);
                      font-size: 21px;
                    "
                    title="Edit"></a>
                  &nbsp;
                  <!-- <a
                    v-on:click="showDeletePopup(data, index)"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    title="Delete"
                  ></a> -->
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    ErrorComponent,
    Field,
    Pagination,
    Multiselect,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      isChecked: false,
      type: "Default",
      list: [],
      detail: {
        is_limit_only_if_position_exist: 0,
        is_first_sell_not_allowed: 0,
        trade_on: "",
        exchange: "",
        no_of_scripts: "",
        margin_amount: "",
      },
      client_list: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
      trades: [
        {
          name: "Qty",
          value: "QTY",
          disable: true,
        },
        {
          name: "Lot",
          value: "LOT",
          disable: true,
        },
      ],
      fromDate: "",
      toDate: "",
    };
  },
  mounted() {
    this.checkAccess();
    this.getClientList();
  },
  methods: {
    checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_segmentSetting == 0) {
        this.$router.push("/access-denied");
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    getDetail(data) {
      this.detail = data;
      this.getClientList();
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      if (this.detail.exchange == "MCX") {
        this.detail.trade_on = "LOT";
      }
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.detail.user_id) {
        searchObj.user_id = this.detail.user_id;
      }
      if (this.detail.exchange) {
        searchObj.exchange = this.detail.exchange;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/client-segment-setting-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    save() {
      if (this.detail.id) {
        this.$api
          .putAPI({
            _action: "/client-segment-setting",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
            }
            this.getList();
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/client-segment-setting",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
            }
            var user_id = this.detail.user_id;
            this.detail = {};
            this.detail.user_id = user_id;
            this.getList();
          })
          .catch(() => {});
      }
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-segment-setting",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.getList();
            }
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
