import { defineRule } from 'vee-validate';

defineRule('required', (value, [name]) => {
    if (!value || value.length <= 0) {
        if (name) {
            return `Please enter ${name}`;
        }
        return 'This field is required';
    }
    return true;
});

defineRule('selectRequired', (value, [name]) => {
    if (!value || value.length <= 0) {
        if (name) {
            return `Please select ${name}`;
        }
        return 'This field is required';
    }
    return true;
});

defineRule('email', value => {
    if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)) {
        return 'Please enter valid email';
    }
    return true;
});

defineRule('phone', value => {
    if (!/^[0-9]{10}$/.test(value)) {
        return 'Please enter valid contact number';
    }
    return true;
});

defineRule('minLength', (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule('confirmed', (value, [target]) => {
    if (value === target) {
        return true;
    }
    return 'Passwords must match';
});

defineRule('min', (value, [target]) => {
    // alert(target)
    if (target && value < Number(target)) {
        return true;
    }
    return 'Value should be less then new qty';
});


defineRule('max', (value, [target]) => {
    if (target && value > Number(target)) {
        return true;
    }
    return 'Value should be greater then new qty';
});

defineRule('maxValue', (value, [target]) => {
    if (target && value <= Number(target)) {
        return true;
    }
    return 'Delay should be less then ' + [target];
});


defineRule('tickSize', (value, [target]) => {
    // alert(target)
    var price = value;
    var tick_size = Number([target][0]);
    console.log('price', price)
    console.log('tick_size', tick_size)
    if (price && tick_size) {
        var n = price;
        n = (n - Math.floor(n)).toFixed(2);
        var tick = tick_size;
        var is_check = (n / tick).toFixed(2) % 1 == 0;
        if (is_check) {
            return true;
        } else {
            return 'Price Should be in multiple of ' + tick_size;
        }
    }
});

defineRule('checkMax1', (value) => {
    // alert(target)
    console.log(typeof value)
    if (Number(value) <= 1 && Number(value) >= 0) {
        return true;
    } else {
        return 'Delivery % must be less then 1';
    }
});

defineRule('max100', (value) => {
    console.log(typeof value)
    if (Number(value) <= 100 && Number(value) >= 0) {
        return true;
    } else {
        return 'Margin % must be less then or equal to 100';
    }
});

